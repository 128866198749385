import React from "react";

const Footer = ({ bg }) => {
  return (
    <div className="footer" style={{ background: `${bg}` }}>
      <div className="footer-text">
        <p>
          <span>©</span> 2023 Kamil Błędowski usługi remontowo-wykończeniowe.
          Wszelkie prawa zastrzeżone.
        </p>
        <p className="it-seek">Realizacja IT-Seek</p>
      </div>
    </div>
  );
};

export default Footer;
