import React from "react";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";

import Galery from "./pages/galery";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/galery" element={<Galery />} />
      </Routes>
    </div>
  );
}

export default App;
