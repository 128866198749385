import React from "react";

const WorkProcces = () => {
  return (
    <div className="workProccesContainer">
      <WorkProccesItem
        number={"01"}
        title={"Kontakt"}
        content={
          "Skontaktuj się z nami, aby rozpocząć projekt. Jesteśmy dostępni, aby wysłuchać Twoich potrzeb."
        }
      />
      <WorkProccesItem
        number={"02"}
        title={"Spotkanie"}
        content={
          "Umówimy się na spotkanie, aby dokładnie omówić Twoje wymagania i pomysły."
        }
      />
      <WorkProccesItem
        number={"03"}
        title={"Wycena"}
        content={
          "Przygotujemy szczegółową wycenę, dostosowaną do Twojego budżetu i oczekiwań."
        }
      />
      <WorkProccesItem
        number={"04"}
        title={"Realizacja"}
        content={
          "Razem stworzymy plan działania, aby projekt został zrealizowany zgodnie z Twoimi oczekiwaniami i harmonogramem."
        }
      />
    </div>
  );
};

export default WorkProcces;

const WorkProccesItem = ({ content, title, number }) => {
  return (
    <div className="WorkItemContainer">
      <div className="WorkTitle">
        <h1>{number}</h1>
        <h3>{title}</h3>
      </div>
      <p>{content}</p>
    </div>
  );
};
