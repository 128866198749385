import React, { useState, useEffect, useCallback } from "react";
import { useSwipeable } from "react-swipeable";

const Modal = ({ image, onClose, onNext, onPrev }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    onClose();
  }, [onClose]);

  const handleNext = useCallback(() => {
    onNext();
  }, [onNext]);

  const handlePrev = useCallback(() => {
    onPrev();
  }, [onPrev]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeModal();
      } else if (e.key === "ArrowRight") {
        handleNext();
      } else if (e.key === "ArrowLeft") {
        handlePrev();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal, handleNext, handlePrev]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  return (
    <div {...handlers} className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="arrow">
          <button onClick={handlePrev}>&#8249;</button>
        </div>
        <img className="modal-image" src={image.src} alt={image.alt} />

        <div className="arrow">
          <button onClick={handleNext}>&#8250;</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
