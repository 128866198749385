import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

const Navbar = () => {
  return (
    <div className="navContainer">
      <nav className="nav">
        <Link to="/" className="site-title">
          <img src={logo} alt="Logo" />
        </Link>
        {/* <i class="fa-solid fa-bars fa-sm"></i> */}
        <ul>
          <li>
            <Link to="/galery">Realizacje</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
