import React, { useState, useEffect } from "react";
import Modal from "../components/modal";

console.log("Początek importu obrazów");
const images = require.context("../images/galery", false, /\.(png|jpe?g|svg)$/);

const imageFiles = images.keys().map(images);

const galleryImages = imageFiles
  .map((image, index) => ({
    id: index + 1,
    src: image,
    alt: `Image ${index + 1}`,
  }))
  .reverse();

const GalleryItem = ({ image, onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        className="GalleryItem"
        src={image.src}
        alt={image.alt}
        loading="lazy"
      />
    </div>
  );
};

const GalleryImages = () => {
  const [visibleImages, setVisibleImages] = useState(16);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const loadMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 20);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const handleNext = () => {
    if (selectedImageIndex < galleryImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    });

    return () => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          closeModal();
        }
      });
    };
  }, []);

  return (
    <>
      <div className="ImagesContainer">
        {galleryImages.slice(0, visibleImages).map((image, index) => {
          return (
            <GalleryItem
              key={image.id}
              image={image}
              onClick={() => openModal(index)}
            />
          );
        })}

        {selectedImageIndex !== null && (
          <Modal
            image={galleryImages[selectedImageIndex]}
            onClose={closeModal}
            onNext={handleNext}
            onPrev={handlePrev}
          />
        )}
      </div>
      {visibleImages < galleryImages.length && (
        <div className="centerer">
          <button onClick={loadMoreImages}>Załaduj więcej</button>
        </div>
      )}
    </>
  );
};

export default GalleryImages;
