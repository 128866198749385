import React from "react";
import Hero from "../components/hero.js";
import SectionTheme from "../components/SectionTheme.js";
import Achiwment from "../components/achivments.js";
import ServiceCard from "../components/ServicesCard.js";
import HelmetIcon from "../images/Helmet.png";
import ArtistIcon from "../images/Artist.png";
import HammerIcon from "../images/Hammer.png";
import RealizationCards from "../components/RealizationCard.js";
import WorkProcces from "../components/workprocces.js";
import Footer from "../components/footer.js";
import Contact from "../components/contact.js";
import Cert from "../components/cert.js";

const Home = () => {
  return (
    <div className="test">
      <Hero
        Title={"Profesjonalne Usługi Wykończeniowo-Remontowe"}
        subtitle1={"Twoje marzenia o idealnym wnętrzu -"}
        subtitle2={"nasza pasja i rzemieślnicza precyzja"}
        show={"yes"}
      />
      <SectionTheme text={"O nas"} />
      <div className="AboutUsSection">
        <div className="AboutUsTextContainer">
          <h3>
            Profesjonalne Usługi Remontowe w Polkowicach, Głogowie, Lubinie
          </h3>
          <p>
            Nasza firma to pasjonatka sztuki wykończania wnętrz i remontów z
            ponad osiemnastoletnim doświadczeniem. Jesteśmy dumni, że od lat
            pomagamy naszym klientom w Polkowicach, Głogowie, Lubinie i
            okolicach przekształcać swoje przestrzenie w miejsca pełne uroku i
            funkcjonalności.
          </p>
        </div>
        <div className="YearsOfExperienceBox">
          <h1>18</h1>
          <h3>lat doświadczenia w branży</h3>
        </div>
      </div>
      <div className="AbousUsAchwimentContainer">
        <Achiwment number={"200+"} content={"Wykonanych Projektów"} />
        <Achiwment number={"100%"} content={"Zadowolenia klientów"} />
      </div>
      <div className="Section">
        <SectionTheme text={"Usługi"} />
        <div className="servicesContainer">
          <ServiceCard
            icon={HelmetIcon}
            title={"Zabudowa"}
            services={
              <>
                <li>
                  <p>Zabudowy z płyt gipsowo-kartonowych</p>
                </li>{" "}
                <li>
                  <p>Podwieszanie sufitów</p>
                </li>
                <li>
                  <p>Cegły gipsowe i betonowe</p>
                </li>
                <li>
                  <p>Betony architektoniczne</p>
                </li>
              </>
            }
          />
          <ServiceCard
            icon={ArtistIcon}
            title={"Wykończenia wnętrz"}
            services={
              <>
                <li>
                  <p>Malowanie ścian</p>
                </li>{" "}
                <li>
                  <p>Szpachlowanie ścian</p>
                </li>
                <li>
                  <p>Tapetowanie</p>
                </li>
                <li>
                  <p>Układanie paneli</p>
                </li>
                <li>
                  <p>Układanie plytek </p>
                </li>
              </>
            }
          />
          <ServiceCard
            icon={HammerIcon}
            title={"Instalacje"}
            services={
              <>
                <li>
                  <p>Instalacje elektryczne</p>
                </li>{" "}
                <li>
                  <p>Instalacje hydrauliczne</p>
                </li>
              </>
            }
          />
        </div>
      </div>
      <SectionTheme text={"Realizacje"} />
      <RealizationCards />
      <div className="Section">
        <SectionTheme text={"Tak działamy"} />
        <WorkProcces />
      </div>
      <SectionTheme text={"Nasze certyfikaty"} />
      <Cert />

      <Contact />

      <Footer bg={"#0D0D0D"} />
    </div>
  );
};

export default Home;
