import React from "react";
import { Element } from "react-scroll";

const Contact = () => {
  return (
    <Element name="contact">
      <div className="Contact">
        <div class="direct-contact-container">
          <ul class="contact-list">
            <li class="list-item">
              <i class="fa fa-map-marker fa-lg"></i>
              <span class="contact-text place">
                Kłobuczyn 131, 59-180 Gaworzyce
              </span>
            </li>

            <li class="list-item">
              <i class="fa fa-phone fa-lg"></i>
              <span class="contact-text phone">
                <a href="tel:1-212-555-5555" title="Give me a call">
                  514 992 306
                </a>
              </span>
            </li>

            <li class="list-item">
              <i class="fa fa-envelope fa-lg"></i>
              <span class="contact-text gmail">
                <a href="mailto:k.bledowski@wp.pl" title="Kontakt przez E-mail">
                  k.bledowski@wp.pl
                </a>
              </span>
            </li>
            <li class="list-item">
              <i class="fa-brands fa-facebook fa-lg"></i>
              <span class="contact-text gmail">
                <a
                  href="https://m.facebook.com/profile.php?id=100005151528352&fref=nf&refid=52&__tn__=~-R"
                  target="_blank"
                  title="Send me an email"
                  rel="noreferrer"
                >
                  Kamil Błędowski
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Contact;
