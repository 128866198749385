import React from "react";
const SectionTheme = ({ text }) => {
  return (
    <div className="sectionTheme">
      <h2>{text}</h2>
      <div className="underline"></div>
    </div>
  );
};
export default SectionTheme;
