import React from "react";
const Achiwment = ({ content, number }) => {
  return (
    <div className="AchivmentContainer">
      <h1>{number}</h1>
      <h3>{content}</h3>
    </div>
  );
};
export default Achiwment;
