import React from "react";
import { Link as Scroll } from "react-scroll";
import { Link } from "react-router-dom";

const Hero = ({ Title, subtitle1, subtitle2, display1, display2 }) => {
  const linkStyle1 = {
    display: display1 === "none" ? "none" : "inline-block",
  };

  const linkStyle2 = {
    display: display2 === "none" ? "none" : "inline-block",
  };

  return (
    <div className="HeroContainer">
      <div className="heroText">
        <h1>{Title}</h1>
        <div>
          <h2>{subtitle1}</h2>
          <h2>{subtitle2}</h2>
        </div>
        <div className="buttonContainer">
          <div>
            <Scroll
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              className="primary"
              style={linkStyle1}
            >
              BEZPŁATNA WYCENA
            </Scroll>
          </div>
          <div>
            <Link to="/galery" className="secondary" style={linkStyle2}>
              NASZE REALIZACJE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
