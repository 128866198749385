import React from "react";
const ServiceCard = ({ title, services, icon }) => {
  return (
    <div className="CardContainer">
      <div
        className="iconCircle"
        style={{ backgroundImage: `url(${icon})` }}
      ></div>
      <h3>{title}</h3>
      <ul>{services}</ul>
    </div>
  );
};
export default ServiceCard;
