import React, { useState } from "react";
import Modal from "../components/modal";
import cert1 from "../images/20220319_194837.png"; // Importuj obrazy
import cert2 from "../images/20220319_194843.png";
import cert3 from "../images/20220319_194846.png";
import cert4 from "../images/20220319_194852.png";

const CertCard = ({ img, onOpen }) => {
  return (
    <div
      className="RealizationBox"
      style={{ backgroundImage: `url(${img})` }}
      onClick={onOpen}
    ></div>
  );
};

const Cert = () => {
  const [modalIndex, setModalIndex] = useState(null);

  const openModal = (index) => {
    setModalIndex(index);
  };

  const closeModal = () => {
    setModalIndex(null);
  };

  const handleNext = () => {
    setModalIndex((prevIndex) =>
      prevIndex === null ? 0 : (prevIndex + 1) % 4
    );
  };

  const handlePrev = () => {
    setModalIndex((prevIndex) =>
      prevIndex === null ? 3 : (prevIndex - 1 + 4) % 4
    );
  };

  return (
    <div className="RealizationContainer">
      {modalIndex !== null && (
        <Modal
          image={{
            src: [cert1, cert2, cert3, cert4][modalIndex],
            alt: "Certificate",
          }}
          onClose={closeModal}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
      {Array.from({ length: 4 }).map((_, index) => (
        <CertCard
          img={[cert1, cert2, cert3, cert4][index]}
          key={index}
          onOpen={() => openModal(index)}
        />
      ))}
    </div>
  );
};

export default Cert;
