import React from "react";
import Hero from "../components/hero.js";
import SectionTheme from "../components/SectionTheme.js";
import Footer from "../components/footer.js";
import GalleryImages from "../components/imageGalery.js";
import Contact from "../components/contact.js";
const Galery = () => {
  return (
    <>
      <Hero
        Title={
          <>
            Nasze Realizacje
            <br />
            Przekonaj się o Naszym Kunszcie
          </>
        }
        subtitle1={"Odkryj, jak tworzymy unikalne przestrzenie"}
        subtitle2={"i odświeżamy wnętrza."}
        display2={"none"}
      />
      <SectionTheme text={"Galeria"} />
      <GalleryImages />
      <Contact />
      <Footer bg={"#212121"} />
    </>
  );
};

export default Galery;
