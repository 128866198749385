import React from "react";
import Bathroom from "../images/bathroom.jpeg";
import Kitchen from "../images/kitchen.jpg";
import SemiRoom from "../images/semiroom.jpg";
import Room from "../images/room.jpg";
import { Link } from "react-router-dom";

const RealizationCards = () => {
  return (
    <div className="RealizationContainer">
      <RealizationCard img={Kitchen} />
      <RealizationCard img={Room} />
      <RealizationCard img={SemiRoom} />
      <RealizationCard img={Bathroom} />
    </div>
  );
};

export default RealizationCards;

const RealizationCard = ({ img }) => {
  return (
    <div className="RealizationBox" style={{ backgroundImage: `url(${img})` }}>
      <Link to={"/galery"}>
        <p className="RealisationTextLink">
          Wszystkie realizacje
          <span>
            <i class="fa-solid fa-arrow-right fa-sm"></i>
          </span>
        </p>
      </Link>
    </div>
  );
};
